import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import MenuIcon from '@mui/icons-material/Menu';

// CSS
import './Header.css'

const pages = [
    {
        label: 'หน้าแรก',
        to: '/'
    },
    {
        label: 'ข่าวสาร',
        to: '/news'
    },
    {
        label: 'เมนู',
        to: '/menus'
    },
    {
        label: 'ติดต่อเรา',
        to: '/contact'
    }
];

const settings = [
    {
        label: 'setting 1',
        to: '/'
    }
];

const Header = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(true);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        const checkPathNotShow = async () => {
            const notShowInPath = [
                '/register',
                '/settingpassword'
            ];
            let settoshow = true;

            await Promise.all(notShowInPath.map((path) => {
                if(location.pathname.includes(path))
                {
                    settoshow = false;
                }
            }));

            setShow(settoshow);
        }
        
        checkPathNotShow();
    }, [location])

    return (
        <>
            {
                show && <AppBar className="navbar">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.2rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    textAlign: 'center'
                                }}
                            >
                                SOMTUM
                            </Typography>
        
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    key={`MenuIconButton`}
                                >
                                    <MenuIcon key={`MenuIcon`} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {/* MOBILE */}
                                    {pages.map((page, idx) => (
                                        <Link to={page.to} className="navbarlink-mobile" key={`link-${idx}`}>
                                            <MenuItem key={`menu-item-page-${idx}`} onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center">
                                                        {page.label}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                </Menu>
                            </Box>
        
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                }}
                            >
                                LOGO
                            </Typography>
        
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                                {pages.map((page, idx) => (
                                    <Link to={page.to} key={`link-to-page-${idx}`}>
                                        <Button
                                            key={idx}
                                            onClick={handleCloseNavMenu}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            className="navbar-desktop-link-hover"
                                        >
                                                {page.label}
                                        </Button>
                                    </Link>
                                ))}
                            </Box>
        
                            <Box sx={{ flexGrow: 0 }}>
                                {
                                    props.isLogged ? <>
                                        <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                        </IconButton>
                                        </Tooltip>
                                        <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                        >
                                        {settings.map((setting, idx) => (
                                            setting.to !== undefined ? <Link to={setting.to} className="navbarlink-mobile" >
                                                <MenuItem key={idx} onClick={handleCloseUserMenu} className="navbar-desktop-link-hover">
                                                    <Typography textAlign="center">
                                                        {
                                                            setting.label
                                                        }
                                                    </Typography>
                                                </MenuItem>
                                            </Link> : <MenuItem key={idx} onClick={handleCloseUserMenu} className="navbar-desktop-link-hover">
                                                <Typography textAlign="center">
                                                    {setting.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                        </Menu>
                                    </> : <>
                                        <Button variant="contained" className="btn-signup" sx={
                                            {
                                                mt: 1
                                            }
                                        }>
                                            <Link to={`/register`}>
                                                สมัครสมาชิก
                                            </Link>
                                        </Button>
                                    </>
                                }
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            }
        </>
    )
}

export default Header;